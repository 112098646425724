import {  useNavigate } from "react-router-dom";
import { useTranslation } from "@i18n";
import stl from "./TamweelOptions.module.css";
import { CDN_LINK } from "@globalConfig";
import "react-lazy-load-image-component/src/effects/blur.css";
import ProgressiveImage from "@componentsShared/ProgressiveImage";
import useHelpers from "@hooks/useHelpers";

const TamweelOptions = (props) => {
  const { t } = useTranslation(["web_home"]);
  const navigate = useNavigate();
    const { createUrl } = useHelpers();

  return (
    <>
      <div className={stl.container}>
        <div className={stl.imgTitle}>
          <ProgressiveImage
            waitUntilInteractive
            src={CDN_LINK + "/assets/images/blueBGTamweel.png"}
            width={179}
            height={80}
            alt="trade_sell"
          />
          <strong>
            <span>
              {t("خيارات", {
                ns: "web_home",
              })}
            </span>
            <span>
              {t("تمويل", {
                ns: "web_home",
              })}
            </span>
          </strong>
        </div>
        <div className={stl.tBox}>
          <div>
            <img
              width={"234px"}
              height={"103px"}
              style={{ maxWidth: "234px", marginBottom: "20px" }}
              src={CDN_LINK + "/assets/images/bb1.webp"}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = CDN_LINK + "/assets/images/no_img.jpg";
              }}
              loading="lazy"
              alt={t(
                " نوفر لك خيارات تمويل متعددة ومن خلال جهات تمويلية مختلفة.",
                {
                  ns: "web_home",
                }
              )}
            />

            <p style={{ position: "relative", top: "7px" }}>
              {t(" نوفر لك خيارات تمويل متعددة ومن خلال جهات تمويلية مختلفة.", {
                ns: "web_home",
              })}
              <br />
            </p>
          </div>
          <div>
            <img
              width={"108px"}
              height={"88px"}
              style={{ maxWidth: "108px", marginBottom: "10px" }}
              src={CDN_LINK + "/assets/images/bb2.webp"}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = CDN_LINK + "/assets/images/no_img.jpg";
              }}
              loading="lazy"
              alt={t(
                "فريق مختص يقوم بانهاء الإجراءات التمويلية لتسهيل عملية التمويل.",
                {
                  ns: "web_home",
                }
              )}
            />

            <p>
              {t(
                "فريق مختص يقوم بانهاء الإجراءات التمويلية لتسهيل عملية التمويل.",
                {
                  ns: "web_home",
                }
              )}
            </p>
          </div>
        </div>
        <a
          className={"blueBtn"}
          href={createUrl(
            "/filters",
            {
              ["condition_id"]: props.isNew ? 1 : 0,
              ["transaction_type_id"]: 2,
            },
            {
              referral_location: "Home",
              browse_variant: "finance_search",
              variant_value: null,
            }
          )}
          onClick={(e) => {
            e.preventDefault();

            navigate(
              createUrl(
                "/filters",
                {
                  ["condition_id"]: props.isNew ? 1 : 0,
                  ["transaction_type_id"]: 2,
                },
                {
                  referral_location: "Home",
                  browse_variant: "finance_search",
                  variant_value: null,
                }
              )
            );
          }}
        >
          {t("شاهد عروض التمويل", {
            ns: "web_home",
          })}
        </a>
      </div>
    </>
  );
};

export default TamweelOptions;
